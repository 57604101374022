import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Tabs } from "antd"
import "antd/es/tabs/style/css"
import styled from "@emotion/styled"
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"
import Img from "gatsby-image"
import { RedFancyButton } from "../Buttons.js"
import case_result from "../../images/case_result.png"

const { TabPane } = Tabs

const ServiceAreasContainer = styled.div`
  color: #fff;
  background-color: #09182d;
  padding: 30px 15px;
  text-align: center;

  @media (min-width: 992px) {
    padding: 0;
  }
`
const ServiceAreasContainerInner = styled.div`
border: 1px solid #163056;
margin: 0 auto;
.ant-tabs-nav.ant-tabs-nav-animated{margin-right: 50px;}
.ant-tabs-tab-active.ant-tabs-tab, .ant-tabs-tab:hover{background-color: #b6212a;  color: #fff;}
.ant-tabs-tab{
  margin-right: 50px !important;
  font-size: 18px;
  
  @media(min-width: 992px){
    font-size: calc(18px + 2 * ((100vw - 992px) / 928));
  }
  @media(min-width: 1920px){
    font-size: 20px;
  }
}
.ant-tabs, .ant-tabs-nav .ant-tabs-tab-active{color: #fff;}
.ant-tabs-ink-bar{background-color: inherit;}
.ant-tabs .ant-tabs-left-bar{
    border-right: 1px solid #163056;
}

}
@media(min-width: 992px){
    .ant-tabs-left-bar{
        display: grid;
        border: 1px solid #163056;
        background-color: #09182d;
    }
    .ant-tabs-extra-content{
        grid-row: 1;
    }
    .ant-tabs-tab{
        width: 100%;
        border-bottom: 1px solid #163056;
        padding-bottom: 40px !important;
    }
    .ant-tabs-left{
        display: flex;
        flex-direction: row;
        background-image: url(${case_result});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    .ant-tabs-left-content{
        border-left: none !important;
        width: 75%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        
    }
    .ant-tabs-tabpane {
        display: block;
    }
    .ant-tabs-tabpane-inactive{
        display: none;
    }
    
}
.ant-tabs-left-content{border-left: 1px solid #163056;}
`
const Title = styled.h4`
  font-size: 36px;
  color: #fff;
  letter-spacing: 2px;
  font-family: "Playfair Display";
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 30px;
`
const ParagraphContainer = styled.div`
  line-height: 24px;
`
const TownContainer = styled.ul`
  text-align: left;
  padding: 0px;
  li {
    font-size: 18px;
    @media (min-width: 992px) {
      font-size: calc(18px + 3 * ((100vw - 992px) / 928));
      margin-left: 10px;
    }
    @media (min-width: 1920px) {
      font-size: 21px;
    }
  }
  @media (min-width: 992px) {
    padding: 0;

    text-align: center;
    width: 56%;
    margin: 0 auto;

    h3 {
      color: #fff;
      font-size: 80px;
      margin-bottom: 15px;
    }
    span {
      color: #f1d1a2;
      letter-spacing: 2px;
    }
    ${ParagraphContainer} {
      margin: 50px 0;
    }
  }
`
const TabLabelContainer = styled.div`
  padding-bottom: 30px;
  border-bottom: 1px solid #163056;
  @media (min-width: 992px) {
    border-bottom: none;
  }
  p {
    margin-bottom: 0;
  }
  text-align: left;

  p {
    color: #f1d1a2;
    letter-spacing: 2px;
    font-size: 12px;
    word-break: break-all;
  }
  @media (min-width: 992px) {
    padding: 0;
    grid-template-columns: 42px 36px auto;
  }
`
const TabsStyled = styled(Tabs)`
  display: none;
  @media (min-width: 992px) {
    display: block;
  }
`
const AccordionItemButtonStyled = styled(AccordionItemButton)`
  color: #fff;
  padding: 10px 18px 0 18px;

  :hover {
    background-color: #b6212a;
  }
`
const AccordionStyled = styled(Accordion)`
  width: 100%;

  ${AccordionItemButtonStyled}[aria-expanded='true'] {
    background-color: #b6212a;
  }

  @media (min-width: 992px) {
    display: none;
  }
`
const StyledH4 = styled.h4`
  color: #fff;
  transition: 0.5s;
  font-family: "Playfair Display";
  font-weight: 500;
  font-size: 44px;
  margin-bottom: 8px;
`

const AccordionItemPanelStyled = styled(AccordionItemPanel)`
  background-color: #0e1f38;
`
const RedFancyButtonStyled = styled(RedFancyButton)`
  color: #fff;
`
const MobileTitleImageContainer = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`
const DesktopTitleContainer = styled.div`
  display: none;
  @media (min-width: 992px) {
    display: block;
    padding-bottom: 1rem;
  }
`
const HomeCaseResults = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          wpgraphql {
            page(id: "cGFnZToyNg==") {
              Homepage {
                caseResultsH4
                caseResultsAccordions {
                  caseResultsAccordionsH4
                  caseResultsAccordionsSubtitle
                  caseResultsAccordionsH3
                  caseResultsAccordionsOtherSubheading
                  caseResultsAccordionsParagraph
                }
              }
            }
          }
          file(relativePath: { eq: "header-bottom-line.png" }) {
            childImageSharp {
              fixed(width: 56, height: 14) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={props => (
        <ServiceAreasContainer>
          <ServiceAreasContainerInner>
            <MobileTitleImageContainer>
              <Title>{props.wpgraphql.page.Homepage.caseResultsH4}</Title>
              <Img fixed={props.file.childImageSharp.fixed} loading="lazy" />
            </MobileTitleImageContainer>
            <AccordionStyled allowZeroExpanded="true">
              {props.wpgraphql.page.Homepage.caseResultsAccordions.map(item => (
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButtonStyled>
                      <TabLabelContainer>
                        <StyledH4>{item.caseResultsAccordionsH4}</StyledH4>
                        <p>{item.caseResultsAccordionsSubtitle}</p>
                      </TabLabelContainer>
                    </AccordionItemButtonStyled>
                  </AccordionItemHeading>

                  <AccordionItemPanelStyled>
                    <TownContainer>
                      <ParagraphContainer
                        dangerouslySetInnerHTML={{
                          __html: item.caseResultsAccordionsParagraph,
                        }}
                      />
                    </TownContainer>
                  </AccordionItemPanelStyled>
                </AccordionItem>
              ))}
            </AccordionStyled>
            <DesktopTitleContainer>
              <Title>{props.wpgraphql.page.Homepage.caseResultsH4}</Title>

              <Img fixed={props.file.childImageSharp.fixed} loading="lazy" />
            </DesktopTitleContainer>

            <TabsStyled defaultActiveKey="1" tabPosition="left">
              {props.wpgraphql.page.Homepage.caseResultsAccordions.map(item => (
                <TabPane
                  tab={
                    <TabLabelContainer>
                      <StyledH4>{item.caseResultsAccordionsH4}</StyledH4>

                      <p>{item.caseResultsAccordionsSubtitle}</p>
                    </TabLabelContainer>
                  }
                  key={item.caseResultsAccordionsSubtitle}
                >
                  <TownContainer>
                    <h3>{item.caseResultsAccordionsH3}</h3>
                    <span>{item.caseResultsAccordionsOtherSubheading}</span>
                    <ParagraphContainer
                      dangerouslySetInnerHTML={{
                        __html: item.caseResultsAccordionsParagraph,
                      }}
                    />
                    <RedFancyButtonStyled to={`/case-results/`}>
                      View All Case Results
                    </RedFancyButtonStyled>
                  </TownContainer>
                </TabPane>
              ))}
            </TabsStyled>
          </ServiceAreasContainerInner>
        </ServiceAreasContainer>
      )}
    />
  )
}
export default HomeCaseResults
